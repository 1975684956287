import { Injectable, computed, inject, Signal, signal, WritableSignal, effect, EffectRef} from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http'
import { Association, AssociationCollection, AssociationComplete, FriendsFamily } from 'src/app/shared/models/association.model';
import { Observable, catchError, map } from 'rxjs'
import { toSignal } from '@angular/core/rxjs-interop'
import { ActivatedRoute } from '@angular/router';
import { AuthService } from './auth.service';
import { User } from 'src/app/shared/models/user.model';

/**
 * ### Association Service
 * Servicio para gestionar las associaciones (amistades o familiares) del usuario. 
 * 
 * #### Propiedades
 * @private httpClient - Peticiones a la Api
 * @private route      - Acceso parametros URL
 * @private user       - Datos del usuario
 * @private endpoint
 * 
 * #### Observables
 * @private associationComplete$ - Llamada a la api y transformación de los datos de respuesta.
 * 
 * #### Signals
 * @public associationsComplete  - Respuesta del Observable como señal. 
 * @public associationsArray     - Derivado de la señal associationsComplete, transformando esta en un array. 
 * @public friendsFamily         - Derivada de la señal friendsFamily$, contiene los datos de friendsFamily {normal:[],inverse:[]}
 * @public selected              - Señal de escritura que espera una colección de asociados seleccionados. 
 * 
 * #### Effects
 * @private recoverSelected  - Función auto invocada cuando associationsComplete cambia de valor, si hay por parametro (url) IDS de Asociados, los recupera y los guarda en selected. 
 * 
 */
@Injectable({
  providedIn: 'root'
})
export class AssociationService {

  private  http:      HttpClient      = inject(HttpClient);
  private  route:     ActivatedRoute  = inject(ActivatedRoute)
  private  user:      User            = inject(AuthService).userData() as User;

  private  endpoint:  string          = '/customer/associations/';
  private  param:     string          = 'show_inverse' // Param que se usa en getFriendsFamily para mostrar {normal:[] | inverse:[]}

  /**
   *  Associatons Complete - Read Only
   *  Calling this endpoint returns and array full of data with the associates. 
   *  When the api call is done we transform the array data into an AssociationCollection (Object).
   * 
   *  @AssociationComplete is the signal
   *  @returns { AssociationCollection }  [{...},{...}] => {id:{...},id:{...}}
   *  
   *  If you need the array data you can use @AssociationsFullArray
   *  @returns { Association[] }  [{...},{...}]
   *  
   */
  private  associationsComplete$:          Observable<AssociationCollection>          = this.http.get<AssociationComplete[]>(this.endpoint).pipe(map(associations => associations.reduce((acc,value)=>(acc[value.tdc_info.id] = value, acc), {} as AssociationCollection)))
  public readonly  associationsComplete:   Signal<AssociationCollection | undefined>  = toSignal(this.associationsComplete$);
  public readonly  associationsArray:      Signal<AssociationComplete[]>              = computed(()=> this.associationsComplete() ? Object.values(this.associationsComplete()!) as AssociationComplete[] : []);

  public addAssociation(email:string): Observable<string | any> {

    return this.http.post<string>(this.endpoint + 'add/', {email}).pipe(catchError(err => err))
  }

  public deleteAssociation(association: Association, mode: keyof FriendsFamily): Observable<string | any> {
    const endpoint: string = mode === 'normal' ?
      `${this.endpoint}${association.id.toString()}/` :
      `${this.endpoint}/inverse/${association.associate_id}/${association.id}/`

    return this.http.delete(endpoint).pipe(
      map(()=> this.getFriendsFamily()),
      catchError(err => err)
    )

  }

  /**
   * Friends Family - Read Only
   * This endpoint returns an object with the keys "normal" and "inverse",
   * is used in FriendsFamily component to display the data. It contains an array of 
   * associates without tdc_info property.
   * 
   * @friendsFamily$ is the signal to store the data.
   * @friendsFamily is the computed signal to access the data.
   * @getFriendsFamily is the function to call the endpoint and set the data in the signal.
   */
  private friendsFamily$:                  WritableSignal<FriendsFamily|undefined>    = signal(undefined);
  public  readonly friendsFamily:          Signal<FriendsFamily|undefined>            = computed(()=> this.friendsFamily$());

  public getFriendsFamily(): void {
    const params = new HttpParams().set(this.param, true)
    this.http.get<FriendsFamily>(this.endpoint,{params: params}).subscribe(friendsFamily => this.friendsFamily$.set(friendsFamily));
  }

  /**
   *  Selected Signal - Writable Signal
   *  Used to store the selected associates or current user to 
   *  access them later in the app.
   *  @returns {AssociationCollection | null}
   */
  protected       selected$:              WritableSignal<AssociationCollection|null> = signal<AssociationCollection|null>(null);
  public readonly selected:               Signal<AssociationCollection | null>       = computed(()=> this.selected$());  
  public readonly selectedData:           Signal<AssociationComplete[]>              = computed(()=>{
    
    // Obtenemos los valores del array y comprobamos si el usuario está en el array. 
    const selectedValues: AssociationComplete[] = this.selected() ? Object.values(this.selected()!) as AssociationComplete[] : [],
          userInArray:    AssociationComplete[] = selectedValues.filter(association => association.id === this.user.tdc_info.id);

    // Si el usuario está en el array lo ponemos en la primera posición y lo devolvemos. Si no, devolvemos el array tal cual.
    return userInArray.length ?
      [userInArray[0], ...selectedValues.filter(association => association.id !== this.user.tdc_info.id)] :
      selectedValues;

  });

  public setSelected(selected: AssociationCollection): void {
    this.selected$.set(selected);
  }

  public resetSelected(): void {
    this.selected$.set(null);
  }

  private  recoverSelected:  EffectRef                                  = effect(()=>{
    if(this.associationsComplete() && this.route.snapshot.queryParams['associations']){
      
      const userId:        string             = this.user.tdc_info.id.toString();
      const queryIds:      string | string[]  = this.route.snapshot.queryParams['associations'];
      const isUserInQuery: boolean            = queryIds.includes(userId) ? true : false;
      
      let selectedAssociations: AssociationCollection = {}

      Array.isArray(queryIds) ? 
      queryIds.forEach(id => selectedAssociations[id] = this.associationsComplete()![id]) :
      selectedAssociations[queryIds] = this.associationsComplete()![queryIds];

      if(isUserInQuery){
        const userAsAssociation: AssociationComplete = {
          id:           this.user.tdc_info.id,
          name:         this.user.tdc_info.account_name,
          associate_id: this.user.tdc_info.id,
          tdc_info:     this.user.tdc_info,
        };
        selectedAssociations[this.user.tdc_info.id] = userAsAssociation;
      }
      this.setSelected(selectedAssociations);
    }
  },{allowSignalWrites:true})
}
